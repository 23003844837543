<template>
  <v-navigation-drawer
    app
    dark
    clipped
    right
    permanent
    disable-resize-watcher
    width="330"
    class="mr-9"
    color="grey19"
  >
    <v-toolbar color="grey23" height="24">
      <v-icon small color="white" class="ml-n3"> icon-chevrons-right</v-icon>
      <v-spacer></v-spacer>
      <v-icon small color="white">icon-more-horizontal</v-icon>
    </v-toolbar>

    <v-toolbar class="mt-2" color="grey16" height="47">
      <v-icon small color="white">icon-chevron-right</v-icon>
      <v-icon small class="ml-1" color="white">icon-eye</v-icon>
      <span class="ml-5">Layer 1</span>
      <v-spacer></v-spacer>
      <v-icon small class="mr-3" color="white">icon-image</v-icon>
      <v-icon small color="grey0">icon-more-vertical</v-icon>
    </v-toolbar>

    <v-toolbar class="mt-2" color="grey16" height="47">
      <v-icon small color="white">icon-chevron-right</v-icon>
      <v-icon small class="ml-1" color="white">icon-eye-off</v-icon>
      <span small class="ml-5">Layer 2</span>
      <v-spacer></v-spacer>
      <v-icon small class="mr-3" color="white">icon-box</v-icon>
      <v-icon small color="grey0">icon-more-vertical</v-icon>
    </v-toolbar>

    <v-toolbar class="mt-2" color="grey16" height="47">
      <v-icon small color="white">icon-chevron-right</v-icon>
      <v-icon small class="ml-1" color="white">icon-eye-off</v-icon>
      <v-slider
        class="mt-5 ml-3"
        dark
        v-model="$store.state.toothopacity"
        min="0"
        max="10"
      ></v-slider>
      <v-icon small class="ml-1 mr-5" color="white">icon-eye</v-icon>
      <v-icon small class="mr-3" color="white">icon-layers</v-icon>
      <v-icon small color="grey0">icon-more-vertical</v-icon>
    </v-toolbar>

    <v-col class="ma-2 rollable">
      <v-row v-for="(item, i) in this.$store.state.msgList" :key="i">
        <v-card light width="100%" height="100%" color="grey">
          <h6 class="ma-1">{{ item.createdAt | timeStampToDate }}</h6>

          <h4 class="ma-1 blue1--text under">@{{ item.to }}</h4>
          <h4 class="ma-1">{{ item.message }}</h4>

          <v-divider color="grey" class="ml-2 mr-2"></v-divider>
          <v-toolbar dense color="grey">
            <v-icon class="ma-2" color="grey0">icon-message-circle</v-icon>
            <v-icon class="ma-2" color="grey0">icon-tag</v-icon>
            <v-spacer></v-spacer>
            <v-icon class="ma-2" color="grey0">icon-thumbs-down</v-icon>
            <v-icon class="ma-2" color="grey0">icon-thumbs-up</v-icon>
          </v-toolbar>
        </v-card>
      </v-row>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
// import { getAuth, onAuthStateChanged } from "firebase/auth";
// import {
//   collection,
//   query,
//   onSnapshot,
//   //orderBy,
//   where,
//} from "firebase/firestore";
//import { db, fb_app } from "@/plugins/firebase";
export default {
  data: () => ({
    msgList: [],
    unsubscribe: null,
  }),
  filters: {
    timeStampToDate(timestamp) {
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp.seconds * 1000);
      let hours = date.getHours();
      let minutes = "0" + date.getMinutes();

      let formattedTime = hours + ":" + minutes.substr(-2);
      let formattedDate =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      return formattedDate + "@" + formattedTime;
    },
  },
  mounted() {
    // const auth = getAuth(fb_app);
    // onAuthStateChanged(auth, (user) => {
    //   if (user) {
    //     const q = query(
    //       collection(db, "messages"),
    //       where("projectID", "==", this.$store.state.projectID)
    //       //orderBy("")
    //     );
    //     this.unsubscribe = onSnapshot(q, (querySnapshot) => {
    //       // this.msgList = undefined;
    //       var list = [];
    //       querySnapshot.forEach((doc) => {
    //         list.push(doc.data());
    //         this.$store.commit("setMsgList", list);
    //         console.log(this.$store.state.msgList);
    //       });
    //     });
    //   }
    // });
  },
};
</script>

<style>
.under {
  text-decoration: underline #0202f8;
}
.rollable {
  overflow-y: scroll;
  height: 400px;
}
</style>
