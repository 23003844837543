import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        QTYellow: "#FFE353",
        QTGreen: "#41cd52",
        grey13: "#1F1F21",
        grey16: "#272729",
        grey19: "#2D2D30",
        grey23: "#39383B",
        grey0: "#69696C",
        blue1: "#2020f8",
      },
    },
  },
});
