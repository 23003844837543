<template>
  <v-app-bar
    app
    clipped-left
    clipped-right
    color="grey16"
    height="42"
    elevation="0"
  >
    <v-card
      width="42"
      height="42"
      color="yellow"
      class="ml-n4 rounded-0 elevation-0"
    >
      <v-img
        class="shrink"
        :src="require('../assets/svg/ArtboardLogo.svg')"
        width="42"
      />
    </v-card>
    <v-card width="22" height="42" color="yellow" class="rounded-0 elevation-0">
      <v-card-actions>
        <v-btn icon class="ml-n2 mt-n3" max-width="22">
          <v-icon color="black">icon-chevron-down</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text class="no-uppercase" dark v-bind="attrs" v-on="on">
          File
        </v-btn>
      </template>
      <v-list dark dense color="grey0">
        <v-list-item v-for="(item, i) in projectList" :key="i">
          <v-btn text dark class="no-uppercase" @click="choseItem(item)">
            {{ item }}</v-btn
          >
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn text dark class="no-uppercase"> Edit </v-btn>
    <v-btn text dark class="no-uppercase"> View </v-btn>
    <v-btn text dark class="no-uppercase"> Window </v-btn>
    <v-card width="33" height="42" color="grey0" class="rounded-0 elevation-0">
      <v-card-actions>
        <v-btn icon max-width="19" max-height="19">
          <v-icon small color="white">icon-corner-up-left</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      width="19"
      height="42"
      color="grey0"
      class="rounded-0 elevation-0 ml-1 left-1"
    >
      <v-card-actions>
        <v-btn icon max-width="19" max-height="19" class="ml-n2">
          <v-icon small color="white">icon-chevron-down</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      width="33"
      height="42"
      color="grey23"
      class="rounded-0 elevation-0 ml-2"
    >
      <v-card-actions>
        <v-btn icon max-width="19" max-height="19">
          <v-icon small color="black">icon-corner-up-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      width="19"
      height="42"
      color="grey23"
      class="rounded-0 elevation-0 ml-1 left-1"
    >
      <v-card-actions>
        <v-btn icon max-width="19" max-height="19" class="ml-n2">
          <v-icon small color="black">icon-chevron-down</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-btn icon max-width="22" max-height="22" class="ml-3">
      <v-icon small color="white">icon-refresh-cw</v-icon>
    </v-btn>
    <v-spacer></v-spacer>
    <span class="mr-2 text-white">Untitled*</span>
    <v-spacer></v-spacer>

    <v-avatar v-if="showAvatar" class="ma-1" color="QTYellow" size="24">{{
      currentUserFirstLetter
    }}</v-avatar>
    <template v-if="showAvatar">
      <div v-for="item in userList" :key="item">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              class="clickable ma-1"
              v-bind="attrs"
              v-on="on"
              color="QTGreen"
              size="24"
              @click="writeNoteToUser(item)"
              >{{ item.charAt(0) }}</v-avatar
            >
          </template>
          <span>{{ item }}</span>
        </v-tooltip>
      </div>
    </template>

    <v-btn class="ml-3" max-width="32" max-height="22" icon>
      <v-icon color="white" small>icon-bell</v-icon>
    </v-btn>
    <v-btn max-width="32" max-height="22" icon>
      <v-icon color="white" small>icon-sun</v-icon>
    </v-btn>
    <v-btn max-width="32" max-height="22" icon>
      <v-icon color="white" small>icon-globe</v-icon>
    </v-btn>
    <v-btn max-width="32" max-height="22" icon class="mr-0">
      <v-icon color="white" small>icon-help-circle</v-icon>
    </v-btn>
    <!-- MSG box -->
    <v-dialog light v-model="showMsgBox" max-width="400" scrollable>
      <v-card width="400" height="240">
        <v-card-title>To: {{ msgTo }} </v-card-title>

        <v-textarea
          class="ml-4 mr-4"
          v-model="msgContent"
          outlined
          label="Message"
          height="80"
        ></v-textarea>

        <v-card-actions>
          <v-btn color="grey13" text @click="showMsgBox = false">
            Cancel
          </v-btn>
          <v-btn color="QTGreen" text @click="sendMsg()">Send</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { fb_app, db } from "@/plugins/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";

export default {
  data() {
    return {
      showAvatar: false,
      showMsgBox: false,
      projectList: [],
      userList: [],
      currentUser: null,
      currentUserFirstLetter: "",

      // msgBox
      msgTo: "",
      msgContent: "",
    };
  },
  mounted() {
    this.projectList = [];
    const auth = getAuth(fb_app);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.showAvatar = true;
        this.currentUser = user;
        this.currentUserFirstLetter = user.displayName.charAt(0);
        this.getProjectList();
        this.getUserList();
      } else {
        this.showAvatar = false;
        this.projectList = undefined;
      }
    });
  },
  methods: {
    async getProjectList() {
      const querySnapshot = await getDocs(collection(db, "ProjectList"));
      this.prokectList = undefined;
      this.projectList = [];
      querySnapshot.forEach((doc) => {
        this.projectList.push(doc.data().name);
      });
    },

    async getUserList() {
      const querySnapshot = await getDocs(collection(db, "users"));
      this.userList = undefined;
      this.userList = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().email !== this.currentUser.email) {
          this.userList.push(doc.data().name);
        }
      });
    },

    async writeNoteToUser(user) {
      console.log(user);
      this.msgTo = user;
      this.showMsgBox = true;
    },

    async sendMsg() {
      await addDoc(collection(db, "messages"), {
        from: this.currentUser.email,
        to: this.msgTo,
        message: this.msgContent,
        createdAt: serverTimestamp(),
        projectID: this.$store.state.currentItem,
      });
      this.showMsgBox = false;
    },

    choseItem(item) {
      this.$store.commit("setMsgList", []);
      this.$store.commit("setCurrentItem", item);
      const q = query(
        collection(db, "messages"),
        where("projectID", "==", item)
        //orderBy("")
      );
      this.unsubscribe = onSnapshot(q, (querySnapshot) => {
        // this.msgList = undefined;
        var list = [];
        querySnapshot.forEach((doc) => {
          list.push(doc.data());
          this.$store.commit("setMsgList", list);
          console.log(this.$store.state.msgList);
        });
      });
    },
  },
};
</script>

<style>
.left-1 {
  position: relative;
  left: -3px;
}
.text-white {
  color: white !important;
}
.no-uppercase {
  text-transform: unset !important;
}
.clickable {
  cursor: pointer;
}
</style>
