<template>
  <v-dialog v-model="show" persistent max-width="300px">
    <div class="mt-5" id="firebaseui-auth-container"></div>
  </v-dialog>
</template>

<script>
import { firebaseConfig } from "@/plugins/firebase";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

export default {
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  data() {
    return {};
  },

  async created() {
    //var cpijson;
  },

  methods: {},

  mounted() {
    var self = this;
    firebase.initializeApp(firebaseConfig);
    const uiConfig = {
      signInFlow: "popup",
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        //firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        signInSuccessWithAuthResult: function () {
          // self.$store.commit(
          //   "setUserName",
          //   authResult.user.displayName.substring(0, 1)
          // );
          self.show = false;
          return false;
        },
      },
    };
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());
    ui.start("#firebaseui-auth-container", uiConfig);
  },
};
</script>
