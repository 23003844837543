<template>
  <v-app>
    <TopBar class="mr-n10" />
    <LeftBar />
    <RightBar />

    <v-main>
      <HelloWorld />
    </v-main>
    <FootBar />
  </v-app>
</template>

<script>
import HelloWorld from "./components/HelloWorld";
import TopBar from "./components/TopBar.vue";
import LeftBar from "./components/LeftBar.vue";
import RightBar from "./components/RightBar.vue";
import FootBar from "./components/FootBar.vue";

export default {
  name: "App",

  components: {
    HelloWorld,
    TopBar,
    LeftBar,
    RightBar,
    FootBar,
  },

  data: () => ({}),
};
</script>

<style scoped>
.v-application {
  background-color: #1f1f21;
}
</style>
