<template>
  <v-container>
    <RightPanel width="30" />
    <BtnGroup />
    <AuthDialog v-if="popAuth" v-model="popAuth" height="400px" />

    <div id="container" class="canvans"></div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import RightPanel from "./RightPanel.vue";
import BtnGroup from "./BtnGroup.vue";
import AuthDialog from "./AuthDialog.vue";

import { fb_app, db } from "@/plugins/firebase";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export default {
  name: "HelloWorld",
  data: () => ({
    overlay: false,
    popAuth: false,

    scene: "",
    toothmodel: "",
    pulpmodel: "",
    light: "",
    light1: "",
    camera: "",
    controls: "",
    renderer: "",

    normallight: 30,
    toothopacity: 10,

    containerWidth: 0,
    containerHeight: 0,
  }),
  components: { RightPanel, BtnGroup, AuthDialog },
  methods: {
    async onClick() {
      this.overlay = true;
      await this.$store.dispatch("getCpiList");
      this.overlay = false;
    },
    onClickAuth() {
      this.popAuth = true;
    },

    initScene() {
      this.calContainerSize();
      this.camera = new THREE.PerspectiveCamera(
        75,
        this.containerWidth / this.containerHeight,
        0.05,
        1000
      );
      this.camera.position.z = 0.3;
      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color(0x1f1f21);
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.physicallyCorrectLights = true;

      this.light = new THREE.DirectionalLight(0xffffff, this.normallight / 10);
      this.light.position = this.camera.position;
      this.scene.add(this.light);
      this.light1 = new THREE.HemisphereLight(0xffffff, 0xaaaaaa, 1.5);
      this.light1.position.set(0, 1, 0);

      this.renderer.setSize(this.containerWidth, this.containerHeight);
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.outputEncoding = THREE.sRGBEncoding;

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);

      this.controls.enablePan = false;
      this.controls.minDistance = 0.15;
      this.controls.maxDistance = 50;
    },

    async initModel(name) {
      if (this.toothmodel) {
        this.toothmodel.traverse(function (child) {
          if (child.isMesh) {
            child.geometry.dispose();
            child.material.dispose();
          }
        });
        this.scene.remove(this.toothmodel);
      }
      if (this.pulpmodel) {
        this.pulpmodel.traverse(function (child) {
          if (child.isMesh) {
            child.geometry.dispose();
            child.material.dispose();
          }
        });
        this.scene.remove(this.pulpmodel);
      }

      //this.scene.remove(this.toothmodel);
      var self = this;
      this.$store.commit("setToothopacity", 10);
      let toothsrc = "/teeth/" + name + ".gltf";
      let number = name.substr(5, 2);
      let pulpsrc = "/teeth/pulp" + number + ".gltf";

      var loader = new GLTFLoader();
      var dracoloader = new DRACOLoader();
      dracoloader.setDecoderPath(
        "https://www.gstatic.com/draco/versioned/decoders/1.4.1/"
      );
      loader.setDRACOLoader(dracoloader);
      loader.load(toothsrc, (gltf) => {
        self.toothmodel = "";
        self.toothmodel = gltf.scene;

        self.toothmodel.traverse((child) => {
          if (child.isMesh) {
            child.material.roughness = 0.0;
            child.material.metalness = 0.1;
            child.material.transparent = true;
          }
        });
        self.toothmodel.matrixAutoUpdate = false;
        self.scene.add(self.toothmodel);
      });

      loader.setDRACOLoader(dracoloader);
      loader.load(pulpsrc, (gltf) => {
        self.pulpmodel = gltf.scene;
        self.scene.add(self.pulpmodel);
        self.pulpmodel.traverse((child) => {
          if (child.isMesh) {
            child.material.roughness = 0.0;
            child.material.metalness = 0.1;
          }
        });
      });

      let container = document.getElementById("container");
      container.appendChild(this.renderer.domElement);
    },

    render() {
      requestAnimationFrame(this.render);
      this.renderer.render(this.scene, this.camera);
    },

    async onWindowResize() {
      await this.calContainerSize();
      this.camera.aspect = this.containerWidth / this.containerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.containerWidth, this.containerHeight);
    },

    async calContainerSize() {
      this.containerWidth = document.getElementById("container").clientWidth;
      this.containerHeight = document.getElementById("container").clientHeight;
    },

    async updateUserInfo(user) {
      await setDoc(doc(db, "users", user.uid), {
        name: user.displayName,
        email: user.email,
        lastTime: serverTimestamp(),
      });
    },
  },

  created() {
    //sign out if refresh window
    const auth = getAuth(fb_app);
    auth.signOut();
  },

  async mounted() {
    const auth = getAuth(fb_app);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        this.showAvatar = true;
        await this.updateUserInfo(user);
      } else {
        // User is signed out
        this.showAvatar = false;
        this.popAuth = true;
      }
      this.initScene();
    });

    window.addEventListener("resize", this.onWindowResize, false);
    this.calContainerSize();
  },
  watch: {
    "$store.state.toothopacity": function (newVal) {
      this.toothmodel.traverse(function (child) {
        if (child.isMesh) {
          child.material.opacity = newVal / 10;
          child.material.transparent = true;
        }
      });
    },

    "$store.state.currentItem": async function (newVal) {
      if (newVal) {
        await this.initModel(newVal);
        this.render();
      }
    },
  },
};
</script>

<style>
.canvans {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
}
</style>
