// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAZH0s5UUKYLRDLcsW9tUdFcQpIQR6FrqU",
  authDomain: "qt-editor.firebaseapp.com",
  projectId: "qt-editor",
  storageBucket: "qt-editor.appspot.com",
  messagingSenderId: "47035181388",
  appId: "1:47035181388:web:483f21341adbb2c98d87e9",
  measurementId: "G-XHCEKS0VGQ",
};

// Initialize Firebase

const fb_app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(fb_app);
const storage = getStorage(fb_app);
const db = getFirestore(fb_app);

export { fb_app, firebaseConfig, storage, db };

if (process.env.NODE_ENV === "production") {
  const analytics = getAnalytics(fb_app);
  console.log("analytics", analytics);
}
