import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userName: "", // Display name of the user
    toothopacity: 10, // Opacity of a tooth
    currentItem: "", // Current item selected in the menu
    msgList: [], // List of messages
  },
  getters: {
    getUserName: (state) => {
      return state.userName;
    },
    getCurrentItem: (state) => {
      return state.currentItem;
    },
  },
  mutations: {
    setUserName(state, userName) {
      state.userName = userName;
    },
    setToothopacity(state, toothopacity) {
      state.toothopacity = toothopacity;
    },
    setCurrentItem(state, currentItem) {
      state.currentItem = currentItem;
    },
    setMsgList(state, msgList) {
      //console.log(msgList);
      state.msgList = msgList;
    },
  },
  actions: {},
  modules: {},
});
