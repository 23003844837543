<template>
  <v-navigation-drawer app clipped right permanent width="38" color="grey16">
    <v-btn color="white" max-width="34" max-height="20" icon class="mt-1">
      <v-icon small>icon-layers</v-icon>
    </v-btn>
    <v-btn color="white" max-width="34" max-height="20" icon>
      <v-icon small>icon-git-pull-request</v-icon>
    </v-btn>
    <v-btn color="white" max-width="34" max-height="20" icon>
      <v-icon small>icon-message-square</v-icon>
    </v-btn>
    <v-btn color="white" max-width="34" max-height="20" icon>
      <v-icon small>icon-share-2</v-icon>
    </v-btn>
  </v-navigation-drawer>
</template>
<script>
export default {
  data: () => ({
    drawer: null,
  }),
};
</script>

<style>
.flap {
  position: relative;
  transform: scaleX(-1);
}
</style>
