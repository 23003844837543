<template>
  <v-navigation-drawer app clipped permanent width="38" color="grey16">
    <v-btn color="white" max-width="34" max-height="20" icon class="mt-1">
      <v-icon small>icon-SelectionToolIcon</v-icon>
    </v-btn>
    <v-btn color="white" max-width="34" max-height="20" icon>
      <v-icon small>icon-DirectSelectionToolIcon</v-icon>
    </v-btn>
    <v-btn color="white" max-width="34" max-height="20" icon>
      <v-icon small>icon-PenIcon</v-icon>
    </v-btn>
    <v-btn color="white" max-width="34" max-height="20" icon>
      <v-icon small>icon-DrawIcon</v-icon>
    </v-btn>

    <v-btn color="white" max-width="34" max-height="20" icon>
      <v-icon small>icon-CreateRectangleIcon</v-icon>
    </v-btn>
    <v-btn color="white" max-width="34" max-height="20" icon>
      <v-icon small>icon-CropIcon</v-icon>
    </v-btn>
    <v-btn color="white" max-width="34" max-height="20" icon>
      <v-icon small>icon-CanvasIcon</v-icon>
    </v-btn>

    <template v-slot:append>
      <v-btn class="mb-2" color="white" max-width="34" max-height="20" icon>
        <v-icon small>icon-SettingsIcon</v-icon>
      </v-btn>
    </template>
  </v-navigation-drawer>
</template>
<script>
export default {
  data: () => ({
    drawer: null,
  }),
};
</script>

<style>
.flap {
  position: relative;
  transform: scaleX(-1);
}
</style>
